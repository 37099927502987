import logo from './logo.svg';
import './App.css';
import ReCAPTCHA from "react-google-recaptcha-enterprise";


function App() {

  const onChange = (value) => {
    console.log("Captcha value:", value);
  }


  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
       
        <ReCAPTCHA
    sitekey="6Ld6ECQoAAAAAFJaQD0c2UiSzOk_3vFFDhN_QW4Z"
    onChange={onChange}
  />


      </header>
    </div>
  );
}

export default App;
